import styled from 'styled-components';

export const SpanStyle = styled.span`
  position: absolute;
  top: -200px;
  @media (max-width: 991px) {
    top: -50px;
  }
`;

export const ParaText = styled.p`
  margin: 0px;
  padding: 0px;
  @media (max-width: 1439px) {
    font-size: 20px;
    line-height: 32px;
    margin: 0px;
    padding: 0px;
  }
  @media (max-width: 1399px) {
    margin: 0px;
    padding: 0px;
  }
  i {
    font-style: italic;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    @media (max-width: 1399px) {
      font-size: 15px;
      line-height: 22px;
    }
    @media (max-width: 767px) {
      font-size: 13px;
      line-height: 20px;
    }
  }
`;
export const ImageStyle = styled.img`
  width: auto;
  height: 100px;
  border: 0;
  vertical-align: top;
  margin: 0 0 40px 0;
  @media (max-width: 1399px) {
    margin: 0 0 30px 0;
  }
  @media (max-width: 991px) {
    height: 100px !important;
    width: auto;
  }
`;
export const ButtonStyle = styled.a`
  display: inline-block;
  position: relative;
  top: 0;
  padding: 17px 35px 13px 35px;
  margin: 0 5px 5px 0;
  font-family: 'Adelle-Bold', sans-serif;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #55aa4c;
  border: 2px solid #55aa4c;
  box-shadow: 0 0 0 2px rgb(255 255 255 / 0%);
  border-radius: 100px;
  cursor: pointer;
  @media (max-width: 1199px) {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 20px;
  }

  @media (max-width: 767px) {
    padding: 10px 20px 6px 20px;
    font-size: 16px;
    line-height: 20px;
    border-radius: 10px;
  }
  :hover {
    top: -5px;
    color: #55aa4c;
    border-color: #55aa4c;
    box-shadow: 0 5px 0 0 #55aa4c;
  }
`;

export const HeaderH2 = styled.h2`
  margin: 0 0 35px 0;
  font-family: 'Figtree', sans-serif;
  font-size: 28px;
  line-height: 35px;
  color: #55aa4c;
  @media (max-width: 1439px) {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 30px;
  }
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 25px;
  }
`;
export const ButtonContainer = styled.div`
  text-align: center;
`;
